<template>
  <div class="profile__notifications">
    <LoadingIndicator v-if="loading" />
    <div
      v-else-if="userNotifications && userNotifications.data && userNotifications.data.length"
      class="pr__list"
    >
      <router-link
        :to="{ name: 'messages_item', params: { id: item.id, url: item.link } }"
        v-for="(item, index) in userNotifications.data"
        :key="index"
        class="pr__list-item"
      >
        <div class="pr__list-item-line">
          <time class="pr__list-item-date" :time="item.date_publication | robotDate">
            {{ item.date_publication | humanDateHoursRu }}
          </time>
          <span class="pr__list-item-status">Новое</span>
        </div>
        <div class="pr__list-item-line">
          <span v-if="item.title" class="line__title-blue">{{ item.title }}</span>
        </div>
        <!--        <div class="pr__list-item-content pr__notification-content">-->
        <!--        </div>-->
      </router-link>
    </div>
    <p v-else class="_item__text">Уведомления отсутствуют</p>
    <PaginationComponent
      v-if="page !== null && total !== null && total > maxOnPage"
      :total="Math.ceil(total / maxOnPage)"
      @change="paginate"
      :page="page"
    />
  </div>
</template>

<script>
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import PaginationComponent from "@/components/Pagination.vue";

export default {
  name: "NotificationsComponent",
  data() {
    return {
      loading: false,
      page: 1,
    };
  },
  beforeMount() {
    this.paginate(1);
  },
  computed: {
    userNotifications() {
      return this.$store.state.userNotifications;
    },
    total() {
      return this.$store.state.userNotifications?.total;
    },
    maxOnPage() {
      return this.$store.state.userNotifications?.per_page;
    },
  },
  methods: {
    paginate(e) {
      const token = localStorage.getItem("apollo-token");
      if (token) {
        this.loading = true;
        this.page = e;
        let pageUrl =
          this.userNotifications && this.userNotifications.first_page_url
            ? this.userNotifications.first_page_url.slice(0, -1) + this.page
            : null;
        this.$store
          .dispatch("GET_USER_NOTIFICATIONS", {
            url: pageUrl,
            token: localStorage.getItem("apollo-token"),
          })
          .then((response) => {
            this.loading = false;
            window.scrollTo(0, 0);
          })
          .catch((response) => {
            this.loading = false;
            console.error(response.data);
          });
      }
    },
  },
  components: {
    LoadingIndicator,
    PaginationComponent,
  },
};
</script>
