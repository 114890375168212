<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <section class="section">
          <h2>Личный кабинет</h2>
          <div class="profile">
            <ul ref="linksList" class="profile__nav-links">
              <li ref="linksItem" v-for="(item, index) in links" :key="index">
                <a
                  @click.prevent="activeNav = index"
                  :class="{ active: activeNav === index }"
                  class="profile__nav-item"
                  href="#"
                >
                  <component :is="item.icon" />
                  <span class="nav-item__title">{{ item.title }}</span>
                  <ArrowRightIcon />
                </a>
              </li>
            </ul>
            <MyProfileComponent v-if="activeNav === 0" />
            <PersonalDataComponent v-if="activeNav === 1" />
            <NotificationsComponent v-if="activeNav === 2" />
            <OfficialAppealsComponent v-if="activeNav === 3" />
            <SocialControlComponent v-if="activeNav === 4" />
            <PublicDiscussionsComponent v-if="activeNav === 5" />
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import ArrowRightIcon from "@/components/svg/ArrowRightSmall.vue";
import UserIcon from "@/components/svg/UserIcon.vue";
import PersonalDataIcon from "@/components/svg/PersonalDataIcon.vue";
import RingIcon from "@/components/svg/RingIcon.vue";
import AppealIcon from "@/components/svg/AppealIcon.vue";
import SCIcon from "@/components/svg/SCIcon.vue";
import PDIcon from "@/components/svg/PDIcon.vue";
import MyProfileComponent from "./components/MyProfile.vue";
import PersonalDataComponent from "./components/PersonalData.vue";
import NotificationsComponent from "./components/Notifications.vue";
import OfficialAppealsComponent from "./components/OfficialAppeals.vue";
import SocialControlComponent from "./components/SocialControl.vue";
import PublicDiscussionsComponent from "./components/PublicDiscussions.vue";

export default {
  name: "UserProfile",
  data() {
    return {
      activeNav: 0,
      links: [
        {
          title: "Мой профиль",
          icon: UserIcon,
          noticesCount: 0,
        },
        {
          title: "Личные данные",
          icon: PersonalDataIcon,
          noticesCount: 0,
        },
        {
          title: "Уведомления",
          icon: RingIcon,
          noticesCount: 0,
        },
        {
          title: "Официальные обращения",
          icon: AppealIcon,
          noticesCount: 0,
        },
        {
          title: "Общественный контроль",
          icon: SCIcon,
          noticesCount: 0,
        },
        {
          title: "Общественные обсуждения",
          icon: PDIcon,
          noticesCount: 0,
        },
      ],
    };
  },
  methods: {
    setActive(index) {
      // скролл к элементу, на который нажали, есть косяк что скроллится вся страница
      this.$refs.linksItem[index].scrollIntoView({
        block: "start",
        inline: index > this.activeNav ? "start" : index < this.activeNav ? "end" : "center",
        behavior: "smooth",
      });
      this.activeNav = index;
    },
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
  components: {
    ArrowRightIcon,
    UserIcon,
    PersonalDataIcon,
    RingIcon,
    AppealIcon,
    SCIcon,
    PDIcon,
    MyProfileComponent,
    PersonalDataComponent,
    NotificationsComponent,
    OfficialAppealsComponent,
    SocialControlComponent,
    PublicDiscussionsComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/textcut.styl"
@import "~@/styles/parts/docs-list.styl"
@import "~@/styles/parts/mod-input.styl"

.profile {
  display grid
  grid-template-columns 360px 1fr
  grid-gap 32px
  +below(1100px) {
    grid-template-columns 1fr
  }
  +below(768px) {
    grid-gap 16px
  }

  &__nav-links {
    display flex
    flex-direction column
    gap 16px
    height 100%
    padding 16px
    background-color var(--color_gray_light)
    overflow-x auto
    +below(1100px) {
      flex-direction row
      gap 8px
      padding 8px 16px
    }

    &::-webkit-scrollbar {
      display none
    }

    li {
      flex-shrink 0

      &:nth-child(2n):after {
        content ""
        display block
        height 1px
        width 100%
        margin-top 16px
        background-color var(--color_gray)
        +below(1100px) {
          display none
        }
      }

      &:last-child:after {
        display none
      }
    }
  }

  &__nav-item {
    display flex
    align-items flex-start
    padding 12px 8px 12px 16px
    border-radius var(--radius)
    gap 16px
    +below(1100px) {
      padding 8px 16px 8px 12px
      gap 8px
    }

    .icon__right {
      +below(1100px) {
        display none
      }
    }

    svg {
      width 24px
      height 24px

      path {
        fill var(--color_dark)
      }
    }

    &:hover {
      background: var(--color_blue_o05)
    }

    &.active {
      background var(--color_blue)
      color var(--color_white)

      svg path {
        fill var(--color_white)
      }

      .nav-item__notices {
        background-color var(--color_white)
        color var(--color_dark)
      }
    }

    .nav-item__title {
      flex-grow 1
    }

    .nav-item__notices {
      font-weight: 500;
      font-size: 0.875em
      line-height: 24px
      width: 24px
      height: 24px
      min-width 24px
      background-color var(--color_red)
      border-radius 50%
      color var(--color_white)
      text-align center
      transition 0.2s
    }
  }

  &__form {
    display flex
    flex-direction column
    gap 16px

    &-item {
      display grid
      grid-gap 32px
      grid-template-columns 230px minmax(auto, 360px)
      width 100%
      +below(768px) {
        grid-template-columns 1fr
        grid-gap 0
      }

      &.no-mobile {
        +below(768px) {
          display none
        }
      }

      &.error {
        input {
          border 1px solid var(--color_red)
        }
      }

      .form-item__error {
        font-size: 0.875em;
        line-height: 20px;
        color: var(--color_red);
        margin-top: 4px
        display: block;
      }

      .profile__input-title {
        font-size: 0.875em
        line-height: 20px;
        margin-top 10px
        margin-bottom 8px
        +below(768px) {
          margin-top: 0;
        }
      }

      .profile__input-wrap {
        position relative
      }

      .password__show-btn {
        position absolute
        right 12px
        top 12px
        z-index 2
      }

      .profile__password-change-title {
        display inline-block
        margin 10px 0
        padding-bottom 4px
        border-bottom 1px solid var(--color_gray)
        color var(--color_blue_dark)
        +below(768px) {
          margin 0
        }
        +below(380px) {
          font-size 0.875em
          line-height 20px
        }
      }
    }

    .profile__psw-change-mobile {
      +above(769px) {
        display none
      }
    }

    &-buttons {
      display flex
      align-items center
      gap 24px
      grid-column: 2 / 3
      +below(768px) {
        grid-column auto
      }
      +below(380px) {
        justify-content center
        flex-wrap wrap
        gap 16px
      }

      button {
        width 180px
        max-width 50%
        +below(380px) {
          width 100%
          max-width 100%
        }
      }

      .profile__form-cancel {
        color var(--color_blue)
        padding 0 11px
      }
    }

    .profile__input-required {
      color var(--color_red)
    }

    .profile__required-mark {
      font-size: 0.75em
      line-height: 18px;
      color var(--color_red)
    }
  }

  &__delete {
    margin-top: 75px;
    padding-top 16px
    border-top 1px solid var(--color_gray)

    +below(768px) {
      margin-top: 44px;
    }
  }
}

.pr {
  &__list-item {
    display flex
    flex-direction column
    padding 24px 0
    border-top 1px solid var(--color_gray_divider)
    transition 0.2s
    +below(768px) {
      padding 12px 0
    }

    &-header {
      cursor pointer
    }

    &:first-child {
      padding-top 0
      border-top none
    }

    &.active {
      .pr__list-item-line {
        margin-bottom: 8px;

        i {
          transform rotate(-180deg)
        }
      }

      .pr__list-item-content {
        padding-top 16px
        margin-bottom 8px
        visibility: visible;
        height: auto;
        overflow: visible;
        opacity: 1;
      }
    }

    &-line {
      display flex
      justify-content space-between
      gap 10px
      +below(420px) {
        gap 6px
      }

      &:first-child {
        margin-bottom: 8px;
      }

      i {
        transition 0.2s
      }

      &.line-top {
        flex-wrap wrap
      }
    }

    .line__title-blue {
      //textcut(1)
      color var(--color_blue)
      +below(420px) {
        font-size 0.875em
        line-height 20px
      }
    }

    &-date {
      +below(420px) {
        font-size 0.875em
        line-height 20px
      }
    }

    &-status {
      font-size: 0.875em
      line-height: 20px;
      color var(--color_red)
      text-align right

      +below(420px) {
        font-size 0.75em
        line-height 16px
      }

      &.status-gray {
        color var(--color_gray_dark)
      }
    }

    &-content {
      display flex
      flex-direction column
      gap 16px
      padding-left 16px
      border-left 1px solid var(--color_blue)
      visibility: hidden;
      height: 0;
      overflow: hidden;
      opacity: 0;
      transition 0.2s

      &.pr__notification-content {
        max-width 80%
      }

      .item-content__block {
        &.block-files {
          .item-content__title {
            margin-bottom: 8px;
          }
        }
      }

      .item-content__title {
        font-size: 0.875em
        line-height: 20px;
        color var(--color_gray_dark)
        margin-bottom: 4px;
        text-decoration underline
      }

      .item-content__inner {
        +below(380px) {
          font-size 0.875em
          line-height 20px
        }
      }
    }
  }
}
</style>
