<template>
  <div class="profile__pd">
    <LoadingIndicator v-if="loading" />
    <div v-else-if="discussions && discussions.data && discussions.data.length" class="pr__list">
      <div
        @click="setActive(index)"
        v-for="(item, index) in discussions.data"
        :key="index"
        class="pr__list-item"
        :class="{ active: isActive(index) }"
      >
        <div class="pr__list-item-line line-top">
          <time class="pr__list-item-date" :time="item.created_at | robotDate">
            {{ item.created_at | formatDate }}
          </time>
          <span
            v-if="item.my_vote && item.my_vote.status && item.my_vote.status.name"
            class="pr__list-item-status"
            >{{ item.my_vote.status.name }}</span
          >
        </div>
        <div class="pr__list-item-line">
          <span v-if="item.title" class="line__title-blue">{{ item.title }}</span>
          <ArrowDownIcon />
        </div>
        <div v-if="item.my_vote" class="pr__list-item-content">
          <div v-if="item.my_vote.type && item.my_vote.type.name" class="item-content__block">
            <div class="item-content__title">Мой голос:</div>
            <div class="item-content__inner">
              {{ item.my_vote.type.name }}
            </div>
          </div>
          <div v-if="item.my_vote.comment" class="item-content__block">
            <div class="item-content__title">Мои замечания и предложения:</div>
            <div class="item-content__inner">
              {{ item.my_vote.comment }}
            </div>
          </div>
          <div v-if="item.files && item.files.length" class="item-content__block block-files">
            <div class="item-content__title">Прикреплённые файлы:</div>
            <div class="item-content__inner">
              <div class="docs-list">
                <a
                  v-for="(file, i) in item.files"
                  :key="i"
                  download
                  target="_blank"
                  :href="$store.state.api + file.url"
                  class="docs-item"
                >
                  <DocumentIcon />
                  <div class="doc-info">
                    <div v-if="file.origin_name" class="doc-title">{{ file.origin_name }}</div>
                    <div class="doc-size">
                      <span>{{ file.type }}</span> ({{ file.size }})
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="item-content__block">
            <div class="item-content__title">Статус:</div>
            <div class="item-content__inner">Ваш голос принят</div>
          </div>
        </div>
      </div>
    </div>
    <p v-else class="_item__text">Уведомления отсутствуют</p>
    <PaginationComponent
      v-if="
        page !== null &&
        discussions &&
        discussions.paginatorInfo &&
        discussions.paginatorInfo.total !== null &&
        discussions.paginatorInfo.total > first
      "
      :total="Math.ceil(discussions.paginatorInfo.total / first)"
      @change="paginate"
      :page="page"
    />
  </div>
</template>

<script>
import ArrowDownIcon from "@/components/svg/ArrowDown.vue";
import DocumentIcon from "@/components/svg/DocumentIcon.vue";
import PaginationComponent from "@/components/Pagination.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import PAGINATE_AUTH_DISCUSSIONS from "@/graphql/profile/PaginateAuthDiscussions.graphql";

export default {
  name: "PublicDiscussionsComponent",
  data() {
    return {
      activeItems: [],
      page: 1,
      first: 13,
      loading: false,
    };
  },
  created() {
    this.load();
  },
  computed: {
    discussions() {
      return this.$store.state.userDiscussions;
    },
  },
  methods: {
    setActive(code) {
      let index = this.activeItems.indexOf(code);
      if (index === -1) {
        this.activeItems.push(code);
      } else {
        this.activeItems.splice(index, 1);
      }
    },
    isActive(code) {
      return this.activeItems.includes(code);
    },
    async load() {
      this.loading = true;
      await this.$apollo.provider.clients.pd
        .query({
          query: PAGINATE_AUTH_DISCUSSIONS,
          variables: Object.assign({
            page: this.page,
            first: this.first,
          }),
          context: {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("apollo-token"),
            },
          },
        })
        .then(({ data }) => {
          this.loading = false;
          if (data.PaginateAuthDiscussions) {
            this.$store.commit("userDiscussions", data.PaginateAuthDiscussions);
          }
        });
    },
    paginate(e) {
      this.page = e;
      this.load();
    },
  },
  components: {
    ArrowDownIcon,
    DocumentIcon,
    PaginationComponent,
    LoadingIndicator,
  },
};
</script>
