<template>
  <div class="my__profile">
    <form @submit.prevent="submitForm" class="profile__form">
      <div class="profile__form-item" :class="{ error: emailForm.email.message }">
        <label class="profile__input-title" for="p_email"
          >Логин (электронная почта) <span class="profile__input-required">*</span></label
        >
        <div class="profile__input-wrap">
          <input
            v-model="emailForm.email.value"
            id="p_email"
            class="profile__input-field"
            type="text"
            placeholder="email@mail.ru"
          />
          <span class="form-item__error" v-if="emailForm.email.message">{{ emailForm.email.message }}</span>
        </div>
      </div>
      <template v-if="!isPasswordChanging">
        <div class="profile__form-item no-mobile">
          <span class="profile__input-title">Пароль</span>
          <div class="profile__form-buttons">
            <button @click="isPasswordChanging = true" class="btn-transpar_blue_dark btn--small">
              Изменить
            </button>
          </div>
        </div>
        <div class="profile__psw-change-mobile">
          <a @click="isPasswordChanging = true" class="link__blue" href="#"> Изменить пароль </a>
        </div>
      </template>
      <template v-else>
        <div class="profile__form-item">
          <div>
            <span class="profile__password-change-title">Смена пароля</span>
          </div>
        </div>
        <div class="profile__form-item" :class="{ error: passwordForm.current_password.message }">
          <label class="profile__input-title" for="password"
            >Старый пароль <span class="profile__input-required">*</span></label
          >
          <div class="profile__input-wrap">
            <input
              v-model="passwordForm.current_password.value"
              id="password"
              :type="showCurrentPassword ? 'text' : 'password'"
              placeholder="Введите пароль"
            />
            <a
              @click.prevent="showCurrentPassword = !showCurrentPassword"
              class="password__show-btn"
              href="#"
            >
              <EyeIcon v-if="showCurrentPassword" />
              <EyeCloseIcon v-else />
            </a>
            <span class="form-item__error" v-if="passwordForm.current_password.message">
              {{ passwordForm.current_password.message }}
            </span>
          </div>
        </div>
        <div class="profile__form-item" :class="{ error: passwordForm.password.message }">
          <label class="profile__input-title" for="new_password"
            >Новый пароль <span class="profile__input-required">*</span></label
          >
          <div class="profile__input-wrap">
            <input
              v-model="passwordForm.password.value"
              id="new_password"
              :type="showPassword ? 'text' : 'password'"
              placeholder="Введите новый пароль"
            />
            <a @click.prevent="showPassword = !showPassword" class="password__show-btn" href="#">
              <EyeIcon v-if="showPassword" />
              <EyeCloseIcon v-else />
            </a>
            <span class="form-item__error" v-if="passwordForm.password.message">
              {{ passwordForm.password.message }}
            </span>
          </div>
        </div>
        <div class="profile__form-item" :class="{ error: passwordForm.password_confirmation.message }">
          <label class="profile__input-title" for="confirm_password"
            >Подтвердите пароль <span class="profile__input-required">*</span></label
          >
          <div class="profile__input-wrap">
            <input
              v-model="passwordForm.password_confirmation.value"
              id="confirm_password"
              :type="showConfirmationPassword ? 'text' : 'password'"
              placeholder="Повторите новый пароль"
            />
            <a
              @click.prevent="showConfirmationPassword = !showConfirmationPassword"
              class="password__show-btn"
              href="#"
            >
              <EyeIcon v-if="showConfirmationPassword" />
              <EyeCloseIcon v-else />
            </a>
            <span class="form-item__error" v-if="passwordForm.password_confirmation.message">
              {{ passwordForm.password_confirmation.message }}
            </span>
          </div>
        </div>
      </template>
      <span class="profile__required-mark">* Обязательное поле для заполнения</span>
      <div class="profile__form-item">
        <div class="profile__form-buttons">
          <button type="submit" class="btn-blue btn--small">
            <LoadingIndicator v-if="loading" title="Загрузка" />
            <template v-else> Сохранить </template>
          </button>
          <a @click="cancelPasswordEdit" v-show="isPasswordChanging" class="profile__form-cancel">Отменить</a>
        </div>
      </div>
      <div class="profile__form-item profile__delete">
        <span class="profile__delete-title profile__input-title">Удаление профиля</span>
        <div class="profile__form-buttons">
          <button @click="deleteAccount" type="button" class="btn-red_tr btn--small">Удалить</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import EyeIcon from "@/components/svg/EyeIcon.vue";
import EyeCloseIcon from "@/components/svg/EyeCloseIcon.vue";
import LoadingIndicator from "components/LoadingIndicator.vue";

export default {
  name: "MyProfileComponent",
  data() {
    return {
      loading: false,
      isPasswordChanging: false,
      showCurrentPassword: false,
      showPassword: false,
      showConfirmationPassword: false,
      emailForm: {
        email: {
          value: null,
          message: null,
        },
      },
      passwordForm: {
        current_password: {
          value: null,
          message: null,
        },
        password: {
          value: null,
          message: null,
        },
        password_confirmation: {
          value: null,
          message: null,
        },
      },
    };
  },
  beforeMount() {
    const token = localStorage.getItem("apollo-token");
    if (token) {
      this.$store.dispatch("GET_USER", token);
    }
  },
  watch: {
    "$store.state.user"() {
      this.emailForm.email.value = this.$store.state.user.email;
    },
  },
  methods: {
    async deleteAccount() {
      const token = localStorage.getItem("apollo-token");
      if (token && confirm("Вы уверены, что хотите удалить этот профиль?")) {
        await this.$store
          .dispatch("POST_DELETE_ACCOUNT", token)
          .then(({ data }) => {
            // возвращаем после удаления на предыдущую страницу перед профилем
            // если вкладки в профиле будут отдельными страницами то возникнут проблемы
            this.$router.go(-1);
            // this.$router.push({ name: "home" });
            this.$store.state.user = null;
            localStorage.removeItem("apollo-token");
            this.$notify({
              title: "Успешно",
              text: "Профиль успешно удален",
              type: "success",
            });
          })
          .catch(() => {
            this.$notify({
              title: "Ошибка",
              text: "Произошла ошибка",
              type: "error",
            });
          });
      }
    },
    cancelPasswordEdit() {
      this.resetForm();
      this.resetValidation();
      this.isPasswordChanging = false;
    },
    async submitForm() {
      if (!this.loading) {
        this.resetValidation();
        this.loading = true;
        const fd = new FormData();
        let form = this.isPasswordChanging ? this.passwordForm : this.emailForm;
        Object.keys(form).forEach((key) => {
          if (form[key].value !== null) fd.append(key, form[key].value);
        });
        const token = localStorage.getItem("apollo-token");
        if (token) {
          await this.$store
            .dispatch(this.isPasswordChanging ? "POST_EDIT_PASSWORD" : "POST_EDIT_EMAIL", {
              token: token,
              data: fd,
            })
            .then(({ data }) => {
              this.loading = false;
              this.$store.dispatch("GET_USER", token);
              this.$notify({
                title: "Успешно",
                text: this.isPasswordChanging ? "Пароль успешно изменен" : "Почта успешно изменена",
                type: "success",
              });
              this.cancelPasswordEdit();
            })
            .catch(({ response }) => {
              if (response.data && response.data.errors) {
                Object.keys(response.data.errors).forEach((message) => {
                  Object.keys(this.isPasswordChanging ? this.passwordForm : this.emailForm).forEach(
                    (field) => {
                      if (field === message) {
                        if (this.isPasswordChanging) {
                          this.passwordForm[field].message = response.data.errors[message][0];
                        } else {
                          this.emailForm[field].message = response.data.errors[message][0];
                        }
                      }
                    }
                  );
                });
              }
              this.loading = false;
            });
        }
      }
    },
    resetForm() {
      Object.keys(this.isPasswordChanging ? this.passwordForm : this.emailForm).forEach((field) => {
        if (this.isPasswordChanging) {
          this.passwordForm[field].value = null;
        } else {
          this.emailForm[field].value = null;
        }
      });
    },
    /**
     * Сброс сообщений от валидации laravel
     */
    resetValidation() {
      Object.keys(this.isPasswordChanging ? this.passwordForm : this.emailForm).forEach((field) => {
        if (this.isPasswordChanging) {
          this.passwordForm[field].message = null;
        } else {
          this.emailForm[field].message = null;
        }
      });
    },
  },
  components: {
    EyeIcon,
    EyeCloseIcon,
    LoadingIndicator,
  },
};
</script>
