<template>
  <div class="profile__personal-data">
    <form @submit.prevent="submitForm" class="profile__form">
      <div class="profile__form-item" :class="{ error: form.surname.message }">
        <label class="profile__input-title" for="p_surname"
          >Фамилия <span class="profile__input-required">*</span></label
        >
        <div class="profile__input-wrap">
          <input
            v-model="form.surname.value"
            id="p_surname"
            class="profile__input-field"
            type="text"
            placeholder="Ваша фамилия"
          />
          <span class="form-item__error" v-if="form.surname.message">{{ form.surname.message }}</span>
        </div>
      </div>
      <div class="profile__form-item" :class="{ error: form.name.message }">
        <label class="profile__input-title" for="p_name"
          >Имя <span class="profile__input-required">*</span></label
        >
        <div class="profile__input-wrap">
          <input
            v-model="form.name.value"
            id="p_name"
            class="profile__input-field"
            type="text"
            placeholder="Ваше имя"
          />
          <span class="form-item__error" v-if="form.name.message">{{ form.name.message }}</span>
        </div>
      </div>
      <div class="profile__form-item" :class="{ error: form.patronymic.message }">
        <label class="profile__input-title" for="p_patronymic">Отчество</label>
        <div class="profile__input-wrap">
          <input
            v-model="form.patronymic.value"
            id="p_patronymic"
            class="profile__input-field"
            type="text"
            placeholder="Ваше отчество"
          />
          <span class="form-item__error" v-if="form.patronymic.message">{{ form.patronymic.message }}</span>
        </div>
      </div>
      <div class="profile__form-item" :class="{ error: form.phone.message }">
        <label class="profile__input-title" for="p_phone"
          >Номер телефона <span class="profile__input-required">*</span></label
        >
        <div class="profile__input-wrap">
          <input
            id="p_phone"
            class="profile__input-field"
            type="text"
            v-model="form.phone.value"
            v-mask="'+7 (###) ###-##-##'"
            placeholder="+7(___) ___-__-__"
          />
          <span class="form-item__error" v-if="form.phone.message">{{ form.phone.message }}</span>
        </div>
      </div>
      <span class="profile__required-mark">* Обязательное поле для заполнения</span>
      <div class="profile__form-item">
        <div class="profile__form-buttons">
          <button class="btn-blue btn--small">
            <LoadingIndicator v-if="loading" title="Загрузка" />
            <template v-else> Сохранить </template>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import LoadingIndicator from "components/LoadingIndicator.vue";

export default {
  name: "PersonalDataComponent",
  data() {
    return {
      loading: false,
      form: {
        name: {
          value: null,
          message: null,
        },
        surname: {
          value: null,
          message: null,
        },
        patronymic: {
          value: null,
          message: null,
        },
        phone: {
          value: null,
          message: null,
        },
      },
    };
  },
  beforeMount() {
    const token = localStorage.getItem("apollo-token");
    if (token) {
      this.$store.dispatch("GET_USER", token);
    }
  },
  watch: {
    "$store.state.user"() {
      if (this.$store.state.user) {
        Object.keys(this.form).forEach((key) => {
          this.form[key].value = this.$store.state.user[key] ? this.$store.state.user[key] : null;
        });
      }
    },
  },
  methods: {
    async submitForm() {
      if (!this.loading) {
        this.resetValidation();
        this.loading = true;
        const fd = new FormData();
        Object.keys(this.form).forEach((key) => {
          if (this.form[key].value !== null) fd.append(key, this.form[key].value);
        });
        const token = localStorage.getItem("apollo-token");
        if (token) {
          await this.$store
            .dispatch("POST_EDIT_PERSONAL_DATA", {
              token: token,
              data: fd,
            })
            .then(({ data }) => {
              this.loading = false;
              this.$store.dispatch("GET_USER", token);
              this.$notify({
                title: "Успешно",
                text: "Личные данные успешно изменены",
                type: "success",
              });
            })
            .catch(({ response }) => {
              if (response.data && response.data.errors) {
                Object.keys(response.data.errors).forEach((message) => {
                  Object.keys(this.form).forEach((field) => {
                    if (field === message) {
                      this.form[field].message = response.data.errors[message][0];
                    }
                  });
                });
              }
              this.loading = false;
            });
        }
      }
    },
    resetValidation() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].message = null;
      });
    },
  },
  components: {
    LoadingIndicator,
  },
};
</script>
