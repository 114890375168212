<template>
  <div class="profile__appeals">
    <LoadingIndicator v-if="loading" />
    <div v-else-if="appeals && appeals.data && appeals.data.length" class="pr__list">
      <div
        v-for="(item, index) in appeals.data"
        :key="index"
        class="pr__list-item"
        :class="{ active: isActive(index) }"
      >
        <div @click="setActive(index)" class="pr__list-item-header">
          <div class="pr__list-item-line line-top">
            <span class="pr__list-item-date">
              Обращение №{{ item.id }} от
              <time :datetime="item.created_at | robotDate">
                {{ item.created_at | humanDateHoursRu }}
              </time>
            </span>
            <span v-if="item.status && item.status.name" class="pr__list-item-status">{{
              item.status.name
            }}</span>
          </div>
          <div class="pr__list-item-line">
            <span class="line__title-blue">{{
              item.name_organization || "Администрация города Махачкалы"
            }}</span>
            <ArrowDownIcon />
          </div>
        </div>
        <div class="pr__list-item-content">
          <div v-if="item.content" class="item-content__block">
            <div class="item-content__title">Текст моего обращения:</div>
            <div class="item-content__inner">
              {{ item.content }}
            </div>
          </div>
          <div v-if="item.status && item.status.name" class="item-content__block">
            <div class="item-content__title">Статус обращения:</div>
            <div class="item-content__inner">
              {{ item.status.name }}
            </div>
          </div>
          <div v-if="item.join_documents && item.join_documents.length" class="item-content__block">
            <div class="item-content__title">Прикреплённые файлы:</div>
            <div class="item-content__inner">
              <div class="docs-list">
                <a
                  v-for="(doc, i) in item.join_documents"
                  :key="i"
                  download
                  target="_blank"
                  :href="$store.state.api + doc.document.url"
                  class="docs-item"
                >
                  <DocumentIcon />
                  <div class="doc-info">
                    <div v-if="doc.document.origin_name" class="doc-title">
                      {{ doc.document.origin_name }}
                    </div>
                    <div v-if="doc.document.type || doc.document.size" class="doc-size">
                      <span>{{ doc.document.type }}</span> ({{ doc.document.size }})
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p v-else class="_item__text">Уведомления отсутствуют</p>
    <PaginationComponent
      v-if="page !== null && total !== null && total > maxOnPage"
      :total="Math.ceil(total / maxOnPage)"
      @change="paginate"
      :page="page"
    />
  </div>
</template>

<script>
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import PaginationComponent from "@/components/Pagination.vue";
import ArrowDownIcon from "@/components/svg/ArrowDown.vue";
import DocumentIcon from "@/components/svg/DocumentIcon.vue";

export default {
  name: "OfficialAppealsComponent",
  data() {
    return {
      loading: false,
      activeItems: [],
      page: 1,
    };
  },
  beforeMount() {
    this.paginate(1);
  },
  computed: {
    appeals() {
      return this.$store.state.userAppeals;
    },
    total() {
      return this.$store.state.userAppeals?.total;
    },
    maxOnPage() {
      return this.$store.state.userAppeals?.per_page;
    },
  },
  methods: {
    setActive(code) {
      let index = this.activeItems.indexOf(code);
      if (index === -1) {
        this.activeItems.push(code);
      } else {
        this.activeItems.splice(index, 1);
      }
    },
    isActive(code) {
      return this.activeItems.includes(code);
    },
    paginate(e) {
      const token = localStorage.getItem("apollo-token");
      if (token) {
        this.activeItems = [];
        this.loading = true;
        this.page = e;
        let pageUrl =
          this.appeals && this.appeals.first_page_url
            ? this.appeals.first_page_url.slice(0, -1) + this.page
            : null;
        this.$store
          .dispatch("GET_USER_APPEALS", {
            url: pageUrl,
            token: localStorage.getItem("apollo-token"),
          })
          .then((response) => {
            this.loading = false;
            window.scrollTo(0, 0);
          })
          .catch((response) => {
            this.loading = false;
            console.error(response.data);
          });
      }
    },
  },
  components: {
    LoadingIndicator,
    PaginationComponent,
    ArrowDownIcon,
    DocumentIcon,
  },
};
</script>
