<template>
  <div class="profile__sc">
    <LoadingIndicator v-if="loading" />
    <div
      v-else-if="userScNotifications && userScNotifications.data && userScNotifications.data.length"
      class="pr__list"
    >
      <div v-for="(item, index) in userScNotifications.data" :key="index" class="pr__list-item">
        <div @click="loadClaim(item.sendable_id)" class="pr__list-item-header">
          <div class="pr__list-item-line line-top">
            <span v-if="item.sendable_id || item.created_at" class="line__title-blue">
              Сообщение #{{ item.sendable_id }} от
              <time :datetime="item.created_at | robotDate">
                {{ item.created_at | formatDate }} г.
              </time>
            </span>
            <span v-if="!item.read_at" class="pr__list-item-status">Статус обновлён</span>
          </div>
          <div class="pr__list-item-line">
            <span v-if="item.comment">{{ item.comment }}</span>
          </div>
        </div>
      </div>
    </div>
    <p v-else class="_item__text">Уведомления отсутствуют</p>
    <PaginationComponent
      v-if="
        page !== null &&
        userScNotifications &&
        userScNotifications.paginatorInfo &&
        userScNotifications.paginatorInfo.total !== null &&
        userScNotifications.paginatorInfo.total > first
      "
      :total="Math.ceil(userScNotifications.paginatorInfo.total / first)"
      @change="paginate"
      :page="page"
    />
  </div>
</template>

<script>
import PaginationComponent from "@/components/Pagination.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import PAGINATE_SC_NOTIFICATIONS from "@/graphql/profile/PaginateSCNotifications.graphql";
import ClaimModal from "@/views/sc/components/modals/ClaimModal.vue";
import GET_ONE_CLAIM from "@/graphql/sc/OneClaim.graphql";

export default {
  name: "SocialControlComponent",
  data() {
    return {
      page: 1,
      first: 13,
      loading: false,
    };
  },
  created() {
    this.load();
  },
  computed: {
    userScNotifications() {
      return this.$store.state.userScNotifications;
    },
  },
  methods: {
    showScModal(e) {
      this.$store.state._modals.push({
        component: ClaimModal,
        options: {
          marker: e,
        },
      });
    },
    async loadClaim(id) {
      this.loading = true;
      await this.$apollo.provider.clients.sc
        .query({
          query: GET_ONE_CLAIM,
          variables: Object.assign({
            id: id,
          }),
          context: {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("apollo-token"),
            },
          },
        })
        .then(({ data }) => {
          this.loading = false;
          if (data.OneClaim) {
            this.showScModal(data.OneClaim);
          }
        });
    },
    async load() {
      this.loading = true;
      await this.$apollo.provider.clients.sc
        .query({
          query: PAGINATE_SC_NOTIFICATIONS,
          variables: Object.assign({
            page: this.page,
            first: this.first,
          }),
          context: {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("apollo-token"),
            },
          },
        })
        .then(({ data }) => {
          this.loading = false;
          if (data.PaginateSCNotifications) {
            this.$store.commit("userScNotifications", data.PaginateSCNotifications);
            this.markAsRead();
          }
        });
    },
    markAsRead() {
      let notReadList = [];
      if (this.userScNotifications && this.userScNotifications.data && this.userScNotifications.data.length) {
        this.userScNotifications.data.forEach((item) => {
          if (!item.read_at) {
            notReadList.push(item.id);
          }
        });
      }
      const fd = new FormData();
      if (notReadList && notReadList.length) {
        notReadList.forEach((item, i) => {
          fd.append(`ids[${i}]`, item);
        });
      }
      this.$store.dispatch("POST_MARK_AS_READ", {
        token: localStorage.getItem("apollo-token"),
        data: fd,
      });
    },
    paginate(e) {
      this.page = e;
      this.load();
    },
  },
  components: {
    PaginationComponent,
    LoadingIndicator,
  },
};
</script>
